import "./App.css";
import { ErrorBoundary } from "./components/error-boundary/ErrorBoundary";
import { RouterConfig } from "./navigation/RouterConfig";
import { OnlineStatusProvider } from "./customHooks/OnlineStatusContext";
import NetworkStatusNotifier from '../src/components/error-message/NetworkStatusNotifier'
function App() {
  return (
    <OnlineStatusProvider>
      <NetworkStatusNotifier />
      <ErrorBoundary>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <RouterConfig />
      </ErrorBoundary>
    </OnlineStatusProvider>
  );
}

export default App;
