import React, { createContext, ReactNode } from "react";
import useOnlineStatus from "./useOnlineStatus";

// Create the context
export const OnlineStatusContext = createContext<boolean | undefined>(undefined);

// Define the props type for the provider
interface OnlineStatusProviderProps {
  children: ReactNode; // Specifies that children is a valid ReactNode
}

// Create the provider component
export function OnlineStatusProvider({ children }: OnlineStatusProviderProps): JSX.Element {
  const isOnline = useOnlineStatus();

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      {children}
    </OnlineStatusContext.Provider>
  );
}
