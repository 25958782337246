import { useState, useEffect } from "react";

interface OnlineStatus {
  isOnline: boolean;
  wasOnline: boolean;
}

function useOnlineStatus(): boolean {
  const [status, setStatus] = useState<OnlineStatus>({
    isOnline: navigator.onLine,
    wasOnline: navigator.onLine,
  });

  useEffect(() => {
    const handleOnline = () => {
      setStatus((prevStatus) => ({
        isOnline: true,
        wasOnline: prevStatus.isOnline,
      }));
      console.log("You are online");
    };

    const handleOffline = () => {
      setStatus((prevStatus) => ({
        isOnline: false,
        wasOnline: prevStatus.isOnline,
      }));
      console.log("You are offline");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Perform initial status check
    setStatus({
      isOnline: navigator.onLine,
      wasOnline: navigator.onLine,
    });

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return status.isOnline;
}

export default useOnlineStatus;
